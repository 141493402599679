var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('router-link', {
    staticClass: "btn btn-sm btn-default",
    attrs: {
      "to": {
        name: _vm.PermissionManagePath.name
      }
    }
  }, [_c('em', {
    staticClass: "fa fa-backward"
  }), _vm._v(" Manage Permission ")])], 1), _vm.item ? _c('b-col', [_c('b-form', {
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('div', {
    staticClass: "card mt-4"
  }, [_c('div', {
    staticClass: "bg-success p-3"
  }, [_c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v(" Edit Permission (" + _vm._s(this.form.name) + ") ")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name *"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "name",
      "type": "text",
      "step": "any",
      "placeholder": "input name",
      "required": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Description"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "description",
      "type": "text",
      "step": "any",
      "placeholder": "input description"
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1)], 1), _c('br'), _c('b-row', [_c('b-col', {
    staticClass: "mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v("Update")])], 1)], 1)], 1)])])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }